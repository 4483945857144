import firebase from 'firebase/app';
import 'firebase/firestore';

const config = {
    apiKey: "AIzaSyBcVI-MzmEpFXUGE_UtO5WhMEfI83F2Sv0",
    authDomain: "projeto-gce-6f68f.firebaseapp.com",
    projectId: "projeto-gce-6f68f",
    storageBucket: "projeto-gce-6f68f.appspot.com",
    messagingSenderId: "848778660794",
    appId: "1:848778660794:web:38c162da348dfe59b26b83",
    measurementId: "G-MTHDSWL03Q"
};

firebase.initializeApp(config);

export const firestore = firebase.firestore();

export default firebase;