import React, { useContext } from 'react';
import './navbar.styles.css';


// Link para outras rotas
import { Link, withRouter } from 'react-router-dom';

// FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';

// Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Tooltip from 'react-bootstrap/Tooltip';

// Logo
import { ReactComponent as MainLogo } from '../../logo.svg';
import { ReactComponent as Mainbr } from '../../br.svg';
import { ReactComponent as Mainen } from '../../en.svg';


import LanguageContext from '../../providers/LanguageContext';


const GitHubTooltip = () => (
  <Tooltip id="github-tooltip">Em desenvolvimento</Tooltip>
);


const NavBar = ({ location }) => {
  const { language, setLanguage } = useContext(LanguageContext);

  

  return (
    <Container fluid className="p-0 fixed-top no-margin">
      <Navbar className="navbar nav-font" collapseOnSelect bg="light" variant="light" expand="lg" sticky="top">
        <Navbar.Brand to="/" as={Link} id="nav-brand">
          <MainLogo id="main-logo" />
          <span id="main-nav-title">
            {language === 'pt' ? 'Saúde Materno-Infantil' : 'Maternal-Child Health'}
          </span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto mr-auto">
            <Nav.Item>
              <Nav.Link as={Link} to="/" active={location.pathname === '/'}>{language === 'pt' ? 'Início' : 'Home'}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/about" active={location.pathname === '/about'} eventKey="link-1">{language === 'pt' ? 'Sobre' : 'About'}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/resultados" active={location.pathname === '/resultados'} eventKey="link-1">{language === 'pt' ? 'Resultados' : 'Results'}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/pesquisas" active={location.pathname === '/pesquisas'} eventKey="link-1">{language === 'pt' ? 'Pesquisas' : 'Research'}</Nav.Link>
            </Nav.Item>
            <Nav.Link href="https://app.powerbi.com/view?r=eyJrIjoiMTljYmYwYTgtYzg1NC00MDllLWIxMzEtNDBiMDBhNWExMDgwIiwidCI6Ijc5ZjZiNjM5LWFiMTItNDI4MC04MDc7LWJkYmVlZjg2OWIzMyIsImMiOjR9&pageName=ReportSectione69d92465ffc48fe980b">{language === 'pt' ? 'Painel' : 'Dashboard'}</Nav.Link>
          </Nav>

          <Nav id="github-nav">
            <Nav.Link href="https://github.com/gcedist" data-toggle="tooltip" title="Em desenvolvimento">
              GitHub <FontAwesomeIcon icon={faGithub} size="lg" />
              <GitHubTooltip />
            </Nav.Link>
            </Nav>
            
           
              <Nav id="language-select-container">
    <div className="select-container">
      <div className="image-container">
        {language === 'pt' ? <Mainbr id="main-flag" /> : <Mainen id="main-flag" />}
      </div>
      <select
        value={language}
        onChange={(e) => setLanguage(e.target.value)}
        className="language-select"
      >
        <option value="pt">pt</option>
        <option value="en">en</option>
      </select>
    </div>
  </Nav>



         

          
            
        </Navbar.Collapse>
      </Navbar>
    </Container>
  );
};

export default withRouter(NavBar);
