import React, { useContext } from 'react';
import LanguageContext from '../../providers/LanguageContext';

// Styles
import './home.styles.css';

// Link for other routes
import { Link } from 'react-router-dom'  // Use to avoid refresh when changing route

// FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'

// Bootstrap
import Container from 'react-bootstrap/Container'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// Components
import LocationImg from '../../img/home/pesquisa.jpg'
import DashboardImg from '../../img/home/graph.jpg'


const Home = () => {
    const { language } = useContext(LanguageContext);


    return (
        <div className="home-content">
            <Jumbotron fluid id="main-home-section">
                <Container>
                    <h1 id="main-title" className="white-text">{language === 'pt' ? 'Acessibilidade aos serviços de saúde materno-infantil' : 'Accessibility to maternal and child health services'}</h1>

                    <p className="main-descricao white-text">
                        {language === 'pt' ? 'Compreendendo o papel das distâncias físicas, entre as gestantes e os serviços de saúde que elas acessam, sobre as medidas de desfecho de saúde materno-infantil.' : 'Understanding the role of physical distances, between pregnant women and the health services they access, on maternal and child health outcome measures.'}
                    </p>

                    <p className="white-text font-small">
                        <Link className="white-text custom-link-light" to="/about">{language === 'pt' ? 'Saiba mais sobre o projeto' : 'Learn more about the project'}  <FontAwesomeIcon icon={faArrowCircleRight} /></Link>
                    </p>

                </Container>
            </Jumbotron>

            <Jumbotron fluid className="full-height-gray-section">
                <Container>
                    <Row >
                        <Col md="6" className="text-left">
                            <h1 className="title">
                                <Link to="/estatisticas" className="invisible-link">
                                    {language === 'pt' ? 'Resultados' : 'Results'}
                                </Link>
                            </h1>
                            <p className="descricao">
                                {language === 'pt' ? 'Acesse aqui algumas informações geradas no âmbito deste projeto!' : 'Access here some information generated within this project!'}
                            </p>

                            <p className="font-small">
                                <Link className="custom-link" to="/estatisticas">{language === 'pt' ? 'Ver estatísticas do estudo' : 'View study statistics'} <FontAwesomeIcon icon={faArrowCircleRight} /></Link>
                            </p>
                        </Col>

                        <Col md="6" >
                            <div className="section-ilustration">
                                <Link to="/estatisticas">
                                    <img
                                        src={DashboardImg}
                                        alt="home-page-chart-icon"
                                        id="home-page-chart-icon"
                                    />
                                </Link>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </Jumbotron>

            <Jumbotron fluid className='full-height-white-section'>
                <Container>
                    <Row className="text-wrap flex-row-reverse mb-2 pb-0">
                        <Col md="6" className="text-left">
                            <h1 className="title">
                                <Link to="/pesquisas" className="invisible-link">
                                    {language === 'pt' ? 'Pesquisas' : 'Research'}
                                </Link>
                            </h1>
                            <p className="descricao">
                                {language === 'pt' ? 'Saiba mais sobre os estudos derivados do projeto.' : 'Learn more about the studies derived from the project.'}
                            </p>
                            <p className="font-small">
                                <Link className="custom-link" to="/pesquisas">{language === 'pt' ? 'Saiba mais' : 'Learn more'} <FontAwesomeIcon icon={faArrowCircleRight} /></Link>
                            </p>
                        </Col>

                        <Col md="6" >
                            <div className="section-ilustration">
                                <Link to="/pesquisas">
                                    <img src={LocationImg}
                                        alt="home-page-map-icon"
                                        id="home-page-map-icon"
                                    />
                                </Link>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </Jumbotron>
        </div>
    );
}


export default Home;
