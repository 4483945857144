import React, { useContext } from 'react';

import './estatisticas.styles.css';

// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Components
import ChartCard from '../../components/chart-card/chart-card.component';


import { Link } from "react-router-dom"
// Firebase
// import { firestore } from '../../firebase/firebase.utils';

// Assets

// BUG: Imagens TEMPORARIAS
import g1 from '../../img/estatisticas/g1.png';
import g2 from '../../img/estatisticas/g2.png';
import g3 from '../../img/estatisticas/g3.png';
import g4 from '../../img/estatisticas/g4.png';
import g5 from '../../img/estatisticas/g5.png';
import g6 from '../../img/estatisticas/g6.png';
import g7 from '../../img/estatisticas/g7.png';
import g8 from '../../img/estatisticas/g8.png';
import g9 from '../../img/estatisticas/g9.png';
import g10 from '../../img/estatisticas/g10.png';
import g11 from '../../img/estatisticas/g11.png';
import g12 from '../../img/estatisticas/g12.png';
import g13 from '../../img/estatisticas/g13.png';
import g14 from '../../img/estatisticas/g14.png';

import g1ing from '../../img/estatisticas/g1.png';
import g2ing from '../../img/estatisticas/g2ing.png';
import g3ing from '../../img/estatisticas/g3ing.png';
import g4ing from '../../img/estatisticas/g4ing.png';
import g5ing from '../../img/estatisticas/g5ing.png';
import g6ing from '../../img/estatisticas/g6.png';
import g7ing from '../../img/estatisticas/g7ing.png';
import g8ing from '../../img/estatisticas/g8ing.png';
import g9ing from '../../img/estatisticas/g9ing.png';
import g10ing from '../../img/estatisticas/g10.png';
import g11ing from '../../img/estatisticas/g11.png';
import g12ing from '../../img/estatisticas/g12.png';
import g13ing from '../../img/estatisticas/g13.png';
import g14ing from '../../img/estatisticas/g14.png';
// import nascVivos from '../../img/estatisticas/nascVivos.png';

import LanguageContext from '../../providers/LanguageContext';

const Estatisticas = () => {
    const { language } = useContext(LanguageContext);

    return (
        <Container fluid className="estatisticas">
            <Container>
                <h1 id="estatisticas-main-title">
                    {language === 'pt' ? 'Resultados' : 'Results'}
                </h1>

                <p className="regular-text text-left">
                    {language === 'pt'
                        ? 'A distância que as gestantes percorrem para o parto está relacionada com diferentes características da demanda e oferta dos serviços de saúde, gerando um desafio para entender suas repercussões na mortalidade infantil. Primeiro, do lado da demanda, descrevemos como fatores de risco e características do município de residência afetam às distâncias percorridas pelas gestantes no Brasil. Segundo, detalhamos algumas estatísticas da oferta de serviços perinatais do SUS e sua disponibilidade geográfica. Finalmente, mostramos que, mesmo levando em consideração fatores relacionados com a demanda e oferta dos serviços, maiores distâncias até o local do parto se traduzem em maiores ocorrências de óbito infantil.'
                        : 'The distance that pregnant women travel for childbirth is related to different characteristics of the demand and supply of health services, posing a challenge to understanding its repercussions on infant mortality. First, on the demand side, we describe how risk factors and characteristics of the municipality of residence affect the distances traveled by pregnant women in Brazil. Second, we detail some statistics on the supply of perinatal services provided by SUS and their geographical availability. Finally, we show that, even when considering factors related to the demand and supply of services, longer distances to the childbirth location result in higher occurrences of infant mortality.'}
                </p>

                <br />

                <h3 className="charts-section-title">
                    {language === 'pt'
                        ? 'A fração de gestantes que viajaram até outro município para a realização do parto e a distância que percorreram aumentou entre 2006 e 2017'
                        : 'The fraction of pregnant women who traveled to another municipality for childbirth and the distance traveled increased between 2006 and 2017'}
                </h3>

                <br />

                <p className="regular-text text-left">
                    {language === 'pt'
                        ? 'A percentagem de gestantes que saem de seu município de residência para ter o filho passou de 23 para 31% e a distância média percorrida aumentou de 48 a 59 km.'
                        : 'The percentage of pregnant women who leave their municipality of residence to give birth increased from 23 to 31%, and the average distance traveled increased from 48 to 59 km.'}
                </p>

                <Row className="charts-section mb-0 mt-0">
                    <Col md="3"></Col>
                    <Col md="6" className="chart-container">
                        <ChartCard
                            chart={language === 'pt' ? g1 : g1ing}
                            kind="img"
                            title={
                                language === 'pt'
                                    ? 'Percentagem de deslocamentos e distância deslocada condicional'
                                    : 'Percentage of conditional displacements and distance traveled'
                            }
                            fonte={
                                language === 'pt'
                                    ? 'Pinho Neto et al. (2023a, Figura 1)'
                                    : 'Pinho Neto et al. (2023a, Figure 1)'
                            }
                        />
                    </Col>
                    <Col md="3"></Col>
                </Row>


                <br />
                <h3 className="charts-section-title">
                    {language === 'pt'
                        ? 'A fração de gestantes que viajam até outro município para a realização do parto é maior quando elas ou os bebês apresentam fatores de risco'
                        : 'The fraction of pregnant women who travel to another municipality for childbirth is higher when they or their babies have risk factors'}
                </h3>
                <br />
                <p className="regular-text text-left">
                    {language === 'pt'
                        ? 'Gestantes com bebês com APGAR5 inferior a oito, baixo peso ao nascer ou prematuridade se deslocaram em maior proporção do que as gestantes e bebês sem esses fatores de risco. Igualmente, fazer cesárea, ter uma gravidez múltipla ou de risco alto está associado a maiores chances de deslocar para o local do parto.'
                        : 'Pregnant women with babies with APGAR5 less than eight, low birth weight, or prematurity traveled in greater proportion than pregnant women and babies without these risk factors. Similarly, having a cesarean section, having a multiple or high-risk pregnancy is associated with a higher likelihood of traveling to the place of birth.'}
                </p>


                <Row className="charts-section mb-0 mt-0">
                    <Col md="3"></Col>
                    <Col md="6" className="chart-container">
                        <ChartCard
                            chart={language === 'pt' ? g2 : g2ing}
                            kind="img"
                            title={
                                language === 'pt'
                                    ? 'Fatores individuais e deslocamento'
                                    : 'Individual factors and displacement'
                            }
                            fonte={
                                language === 'pt'
                                    ? 'Pinho Neto et al. (2023a, Figura 9)'
                                    : 'Pinho Neto et al. (2023a, Figure 9)'
                            }
                        />
                    </Col>
                    <Col md="3"></Col>
                </Row>

                <br />

                <h3 className="charts-section-title">
                    {language === 'pt'
                        ? 'Gestantes de municípios com melhores condições socioeconômicas percorrem, em média, menores distâncias'
                        : 'Pregnant women from municipalities with better socioeconomic conditions travel shorter distances on average'}
                </h3>

                <br />

                <p className="regular-text text-left">
                    {language === 'pt'
                        ? 'Em particular, quanto maior a renda per capita e o emprego, e quanto melhor a educação e a cobertura sanitária dos municípios menor tendem a ser as distâncias percorridas. Por outro lado, elevadas taxas de pobreza dos municípios estão associadas a maiores distâncias percorridas pelas gestantes.'
                        : 'In particular, higher per capita income, employment, education, and health coverage in municipalities are associated with shorter distances traveled. On the other hand, higher poverty rates in municipalities are associated with longer distances traveled by pregnant women.'}
                </p>

                <Row className="charts-section mb-0 mt-0">
                    <Col md="3"></Col>
                    <Col md="6" className="chart-container">
                        <ChartCard
                            chart={language === 'pt' ? g3 : g3ing}
                            kind="img"
                            title={
                                language === 'pt'
                                    ? 'Fatores socioeconômicos e distância'
                                    : 'Socioeconomic factors and distance'
                            }
                            fonte={
                                language === 'pt'
                                    ? 'Pinho Neto et al. (2023a, Figura 10)'
                                    : 'Pinho Neto et al. (2023a, Figure 10)'
                            }
                        />
                    </Col>
                    <Col md="3"></Col>
                </Row>

                <br />
                <p className="regular-text text-left">
                    {language === 'pt'
                        ? 'Maiores detalhes no estudo: '
                        : 'More details in the study: '}
                    <Link to="/pesquisas" active="/pesquisas">
                        {language === 'pt'
                            ? 'Inequalities in access to health facilities for birth in Brazil.'
                            : 'Inequalities in access to health facilities for birth in Brazil.'}
                    </Link>
                </p>
                <br />


                <Row>
                    <Col md="6">
                        <h3 className="charts-section-title">
                            {language === 'pt'
                                ? 'A disponibilidade de estabelecimentos de saúde do SUS com maior capacidade e infraestrutura adequada para atenção perinatal melhorou entre 2007 e 2021'
                                : 'The availability of SUS healthcare facilities with greater capacity and adequate infrastructure for perinatal care improved between 2007 and 2021'}
                        </h3>
                        <br />
                        <p className="regular-text text-left">
                            {language === 'pt'
                                ? 'O total de estabelecimentos com atendimento ao parto reduziu no período. Porém, a quantidade de estabelecimentos Nível III (i.e., de elevada capacidade tecnológica, incluindo acesso a especialistas e terapia intensiva neonatal e para adultos) aumentou de 94 em 2007 para 184 em 2021. Estes estabelecimentos tipicamente são recomendados para a atenção de casos de alto risco.'
                                : 'The total number of delivery care facilities decreased during the period. However, the number of Level III facilities (i.e., with high technological capacity, including access to specialists and neonatal and adult intensive care) increased from 94 in 2007 to 184 in 2021. These facilities are typically recommended for the care of high-risk cases.'}
                        </p>
                        <ChartCard
                            chart={language === 'pt' ? g4 : g4ing}
                            kind="img"
                            title={
                                language === 'pt'
                                    ? 'Evolução dos estabelecimentos de saúde por nível de cuidado perinatal'
                                    : 'Evolution of healthcare facilities by level of perinatal care'
                            }
                            fonte={
                                language === 'pt'
                                    ? 'Pinho Neto et al. (2023b, Figura 1)'
                                    : 'Pinho Neto et al. (2023b, Figure 1)'
                            }
                        />
                    </Col>
                    <Col md="6">
                        <h3 className="charts-section-title">
                            {language === 'pt'
                                ? 'Os nascimentos aconteceram com mais frequência em estabelecimentos do SUS com maior capacidade tecnológica'
                                : 'Births occurred more frequently in SUS facilities with greater technological capacity'}
                        </h3>
                        <br />
                        <br />
                        <p className="regular-text text-left">
                            {language === 'pt'
                                ? 'A percentagem de nascimentos aumentou de 11 para 27% em estabelecimentos Nível III e de 26 para 29% em estabelecimentos Nível II. Estabelecimentos Nível II, com uma equipe de ginecologia e pediatria, conseguem atender cirurgias obstétricas básicas e dar cuidados neonatais intermediários.'
                                : 'The percentage of births increased from 11 to 27% in Level III facilities and from 26 to 29% in Level II facilities. Level II facilities, with a team of gynecology and pediatrics, are able to perform basic obstetric surgeries and provide intermediate neonatal care.'}
                        </p>
                        <br />
                        <ChartCard
                            chart={language === 'pt' ? g5 : g5ing}
                            kind="img"
                            title={
                                language === 'pt'
                                    ? 'Evolução dos nascimentos em unidades por nível de cuidado perinatal'
                                    : 'Evolution of births in units by level of perinatal care'
                            }
                            fonte={
                                language === 'pt'
                                    ? 'Pinho Neto et al. (2023b, Figura 2a)'
                                    : 'Pinho Neto et al. (2023b, Figure 2a)'
                            }
                        />
                    </Col>
                </Row>
                <br />
                <br />

                <h3 className="charts-section-title">
                    {language === 'pt'
                        ? 'A distância para os estabelecimentos de saúde de maior capacidade tecnológica é muito maior do que a distância para os demais estabelecimentos, embora tenha reduzido entre 2007 e 2021'
                        : 'The distance to healthcare facilities with greater technological capacity is much greater than the distance to other facilities, although it has decreased between 2007 and 2021'}
                </h3>
                <Row className="charts-section mb-0 mt-0">
                    <Col md="2"></Col>
                    <Col md="8" className="chart-container">
                        <ChartCard
                            chart={language === 'pt' ? g6 : g6ing}
                            kind="img"
                            title={
                                language === 'pt'
                                    ? 'Evolução da distância até o estabelecimento mais próximo por nível de cuidado perinatal'
                                    : 'Evolution of distance to the nearest facility by level of perinatal care'
                            }
                            fonte={
                                language === 'pt'
                                    ? 'Pinho Neto et al. (2023b, Figura 4)'
                                    : 'Pinho Neto et al. (2023b, Figure 4)'
                            }
                        />
                    </Col>
                    <Col md="2"></Col>
                </Row>
                <br />
                <p className="regular-text text-left">
                    {language === 'pt'
                        ? 'Maiores detalhes no estudo: '
                        : 'Further details in the study: '}
                    <Link to="/pesquisas" active="/pesquisas">
                        {language === 'pt'
                            ? 'Disponibilidade geográfica de serviços perinatais no Brasil.'
                            : 'Geographic availability of perinatal services in Brazil.'}
                    </Link>
                </p>
                <br />

                <br />
                <h3 className="charts-section-title">
                    {language === 'pt'
                        ? 'A mortalidade infantil reduziu levemente entre 2006 e 2017, sendo as mortes neonatais e por causas evitáveis as que concentraram a maior parte das mortes infantis'
                        : 'Infant mortality decreased slightly between 2006 and 2017, with neonatal deaths and avoidable causes accounting for the majority of infant deaths'}
                </h3>
                <br />
                <p className="regular-text text-left">
                    {language === 'pt'
                        ? 'A taxa de mortalidade infantil teve uma leve queda entre 2006 e 2009 e depois permaneceu estável. Em 2017, as taxas de mortalidade por causas evitáveis e neonatal precoce (i.e., durante a primeira semana de vida) foram de 8,7 e 6,4 por 1000 nascidos vivos, respectivamente, constituindo-se na maior parcela das mortes até um ano de vida (cuja taxa foi de 11,2 por 1000 nascidos vivos).'
                        : 'The infant mortality rate had a slight decrease between 2006 and 2009 and then remained stable. In 2017, the mortality rates for avoidable causes and early neonatal deaths (i.e., during the first week of life) were 8.7 and 6.4 per 1000 live births, respectively, accounting for the majority of deaths within the first year of life (with a rate of 11.2 per 1000 live births).'}
                </p>

                <Row className="charts-section mb-0 mt-0">
                    <Col md="2"></Col>
                    <Col md="8" className="chart-container">
                        <ChartCard
                            chart={language === 'pt' ? g7 : g7ing}
                            kind="img"
                            title={
                                language === 'pt'
                                    ? 'Mortalidade a nível nacional por tipo'
                                    : 'National mortality by type'
                            }
                            fonte={
                                language === 'pt'
                                    ? 'Pinho Neto et al. (2023c, Figura 1)'
                                    : 'Pinho Neto et al. (2023c, Figure 1)'
                            }
                        />
                    </Col>
                    <Col md="2"></Col>
                </Row>

                <br />
                <h3 className="charts-section-title">
                    {language === 'pt'
                        ? 'O padrão do percentual de viajantes intermunicipais depende do tipo de rota a ser feita'
                        : 'The pattern of the percentage of intermunicipal travelers depends on the type of route to be taken'}
                </h3>
                <br />

                <br />
                <p className="regular-text text-left">
                    {language === 'pt'
                        ? 'Quando os deslocamentos entre municípios ocorrem do nascimento para óbito ou da residência para nascimento e para óbito, a porcentagem de viagens se manteve estável durante o período. Já com relação às viagens partindo de residência, tanto para nascimento quanto para óbito, nota-se um percentual crescente de grávidas realizando esses trajetos.'
                        : 'When intermunicipal travel occurs from birth to death or from residence to birth and death, the percentage of travel remained stable during the period. However, with regard to travel from residence to both birth and death, there is an increasing percentage of pregnant women undertaking these journeys.'}
                </p>


                <Row className="charts-section mb-0 mt-0">
                    <Col md="2"></Col>
                    <Col md="8" className="chart-container">
                        <ChartCard
                            chart={language === 'pt' ? g8 : g8ing}
                            kind="img"
                            title={language === 'pt' ? 'Padrões de deslocamento por tipo' : 'Traveling Patterns by Type'}
                            subtitle={language === 'pt' ? 'Percentagem de deslocamentos' : 'Percentage of Travel'}
                            fonte={language === 'pt' ? 'Pinho Neto et al. (2023c, Figura 8)' : 'Pinho Neto et al. (2023c, Figure 8)'}
                        />
                    </Col>
                    <Col md="2"></Col>
                </Row>
                <br />
                <p className="regular-text text-left">
                    {language === 'pt'
                        ? 'Maiores detalhes no estudo: '
                        : 'More details in the study: '}
                    <Link to="/pesquisas" active="/pesquisas">
                        {language === 'pt'
                            ? 'Padrões de deslocamento de gestantes: uma análise de 12 anos de mortalidade infantil no Brasil.'
                            : 'Traveling patterns of pregnant women: an analysis of 12 years of infant mortality in Brazil.'}
                    </Link>
                </p>
                <br />

                <h3 className="charts-section-title">
                    {language === 'pt'
                        ? 'A mortalidade das crianças menores de um ano é maior se as gestantes precisam viajar até outro município para o parto e aumenta ainda mais quanto maior for a distância percorrida'
                        : 'Infant mortality is higher for children whose mothers need to travel to another municipality for childbirth, and it increases even more with longer distances traveled'}
                </h3>
                <br />
                <p className="regular-text text-left">
                    {language === 'pt'
                        ? 'A probabilidade de mortalidade é 0,5 ponto percentual maior para crianças cujas mães viajaram para dar à luz. Além disso, a probabilidade de mortalidade é cerca de 2 pontos percentuais maior para crianças cujas mães percorreram mais de 100 Km, quando comparadas com aquelas que viajaram não mais que 50 Km.'
                        : 'The probability of mortality is 0.5 percentage point higher for children whose mothers traveled to give birth. Furthermore, the probability of mortality is about 2 percentage points higher for children whose mothers traveled over 100 km, compared to those who traveled no more than 50 km.'}
                </p>
                <Row className="charts-section mb-0 mt-0">
                    <Col md="6" className="chart-container">
                        <ChartCard
                            chart={language === 'pt' ? g9 : g9ing}
                            kind="img"
                            title={language === 'pt' ? 'Curvas de mortalidade de crianças menores de um ano de vida condicionais a viajar ou não para o nascimento' : 'Mortality curves of children under one year of age conditional on traveling or not for birth'}
                            subtitle={language === 'pt' ? 'Viajante versus Não viajante' : 'Traveler versus Non-traveler'}
                            fonte={language === 'pt' ? 'Pinho Neto et al. (2023d, Figura 7a)' : 'Pinho Neto et al. (2023d, Figure 7a)'}
                        />
                    </Col>
                    <Col md="6" className="chart-container">
                        <ChartCard
                            chart={language === 'pt' ? g10 : g10ing}
                            kind="img"
                            title={language === 'pt' ? 'Curvas de mortalidade de crianças menores de um ano de vida por distância ao local de nascimento' : 'Mortality curves of children under one year of age by distance to birthplace'}
                            subtitle={language === 'pt' ? 'Por distância percorrida' : 'By distance traveled'}
                            fonte={language === 'pt' ? 'Pinho Neto et al. (2023d, Figura 7b)' : 'Pinho Neto et al. (2023d, Figure 7b)'}
                        />
                    </Col>
                </Row>
                <br />

                <h3 className="charts-section-title">
                    {language === 'pt'
                        ? 'Após isolar o efeito das distâncias de outros fatores socioeconômicos e de oferta de serviços, inclusive considerando somente gestações de baixo risco, a mortalidade infantil continua sendo maior para gestantes que percorrem distâncias maiores para a realização do parto'
                        : 'After isolating the effect of distances from other socioeconomic and service supply factors, even considering only low-risk pregnancies, infant mortality remains higher for women who travel longer distances for childbirth'}
                </h3>
                <br />
                <p className="regular-text text-left">
                    {language === 'pt'
                        ? 'Com base em um modelo de regressão linear multivariada para dados de painel, que mede o efeito da distância percorrida para o parto na mortalidade infantil na subamostra de gestações de baixo risco, estima-se que para cada 10 km adicionais de deslocamento há um incremento na taxa de mortalidade em torno de 0,10 morte por mil nascidos vivos. Essas estimações controlam pelos fatores socioeconômicos, tendências municipais e do hospital de destino, que possivelmente estão correlacionados com as distâncias.'
                        : 'Based on a multivariate linear regression model for panel data that measures the effect of travel distance for childbirth on infant mortality in the subsample of low-risk pregnancies, it is estimated that for every additional 10 km of travel distance, there is an increase in the mortality rate of around 0.10 deaths per thousand live births. These estimates control for socioeconomic factors, municipal and destination hospital trends, which are potentially correlated with the distances.'}
                </p>
                <h3 className="charts-section-title">
                    {language === 'pt'
                        ? 'Aproximadamente um terço do efeito da distância na mortalidade infantil advém de mortes que acontecem na primeira semana de vida ou são evitáveis'
                        : 'Approximately one-third of the effect of distance on infant mortality comes from deaths that occur in the first week of life or are preventable'}
                </h3>
                <br />
                <p className="regular-text text-left">
                    {language === 'pt'
                        ? 'O efeito da distância na mortalidade neonatal precoce é de 0,03 morte por mil nascidos vivos para cada 10 km adicionais de deslocamento, o que representa 34% do efeito sobre mortalidade infantil. Similarmente, o efeito em mortalidade infantil por causas evitáveis é de 0,03 morte por mil nascidos vivos para cada 10 km adicionais de deslocamento, isto é, 30% do efeito total.'
                        : 'The effect of distance on early neonatal mortality is 0.03 deaths per thousand live births for every additional 10 km of travel distance, which represents 34% of the effect on infant mortality. Similarly, the effect on infant mortality from preventable causes is 0.03 deaths per thousand live births for every additional 10 km of travel distance, which is 30% of the total effect.'}
                </p>
                <Row className="charts-section mb-0 mt-0">
                    <Col md="6" className="chart-container">
                        <ChartCard
                            chart={language === 'pt' ? g11 : g11ing}
                            kind="img"
                            title={language === 'pt' ? 'Efeito distância na taxa de mortalidade' : 'Effect of Distance on Mortality Rate'}
                            subtitle={language === 'pt' ? 'por 10 km' : 'per 10 km'}
                            fonte={language === 'pt' ? 'Elaboração própia a partir de Pinho Neto et al. (2023d, Tabela 2A)' : 'Own elaboration based on Pinho Neto et al. (2023d, Table 2A)'}
                        />
                    </Col>
                    <Col md="6" className="chart-container">
                        <ChartCard
                            chart={language === 'pt' ? g12 : g12ing}
                            kind="img"
                            title={language === 'pt' ? 'Efeito distância na taxa de mortalidade nos que deslocam' : 'Effect of Distance on Mortality Rate for Travelers'}
                            subtitle={language === 'pt' ? 'por 10 km' : 'per 10 km'}
                            fonte={language === 'pt' ? 'Elaboração própia a partir de Pinho Neto et al. (2023d, Tabela 2C)' : 'Own elaboration based on Pinho Neto et al. (2023d, Table 2C)'}
                        />
                    </Col>
                </Row>

                <br />
                <h3 className="charts-section-title">
                    {language === 'pt'
                        ? 'A proximidade dos estabelecimentos do SUS com melhor infraestrutura reduz a mortalidade infantil em gestações de alto risco, mesmo depois de isolar efeitos de outros fatores de risco e sociodemográficos'
                        : 'The proximity of SUS facilities with better infrastructure reduces infant mortality in high-risk pregnancies, even after isolating the effects of other risk factors and sociodemographic characteristics'}
                </h3>
                <br />
                <p className="regular-text text-left">
                    {language === 'pt'
                        ? 'A figura abaixo mostra os coeficientes e intervalos de confiança de um modelo de regressão linear para dados de painel. O modelo mede o efeito das distâncias para infraestrutura mais próxima sobre mortalidade infantil, isolando-o de fatores de risco e de características socioeconômicas municipais. As distâncias estão expressas em desvio-padrão para fazê-las comparáveis entre si. Os efeitos maiores e estatisticamente significativos estão nas distâncias para leitos de cuidados intensivos neonatais, de cirurgia pediátrica e de cuidados intermediários neonatais.'
                        : 'The figure below shows the coefficients and confidence intervals of a linear regression model for panel data. The model measures the effect of distances to the nearest infrastructure on infant mortality, isolating it from risk factors and municipal socioeconomic characteristics. Distances are expressed in standard deviations to make them comparable to each other. The larger and statistically significant effects are in the distances to neonatal intensive care beds, pediatric surgery, and neonatal intermediate care.'}
                </p>
                <Row className="charts-section mb-0 mt-0">
                    <Col md="2"></Col>
                    <Col md="8" className="chart-container">
                        <ChartCard
                            chart={language === 'pt' ? g13 : g13ing}
                            kind="img"
                            title={language === 'pt' ? 'Efeitos da distancia à infraestrutura mais próxima na mortalidade infantil por risco na gravidez' : 'Effects of Distance to Nearest Infrastructure on Infant Mortality by Pregnancy Risk'}
                            fonte={language === 'pt' ? 'Pinho Neto et al. (2023d, Figura 8a)' : 'Pinho Neto et al. (2023d, Figure 8a)'}
                        />
                    </Col>
                    <Col md="2"></Col>
                </Row>
                <br />
                <h3 className="charts-section-title">
                    {language === 'pt'
                        ? 'O efeito da proximidade a estabelecimentos SUS de maior capacidade tecnológica na mortalidade infantil se concentra nas mortes neonatais precoces e nas por causas evitáveis'
                        : 'The effect of proximity to SUS facilities with higher technological capacity on infant mortality is concentrated in early neonatal deaths and preventable causes'}
                </h3>
                <br />
                <br />
                <p className="regular-text text-left">
                    {language === 'pt'
                        ? 'A figura abaixo mostra os resultados do efeito da distância para os estabelecimentos Nível I, II e III mais próximos sobre mortalidade infantil, neonatal precoce e por causas evitáveis para gestações de alto risco. Note que a proximidade para estabelecimentos Nível I (de atendimento básico) não tem efeito na mortalidade nestes casos. Por outro lado, o distanciamento de estabelecimentos Nível II e III incrementa a mortalidade infantil, principalmente a neonatal precoce e a por causas evitáveis. A mortalidade neonatal precoce contribui em 73 e 131% sobre o efeito da distância para estabelecimentos Nível II e III na mortalidade infantil, respectivamente. Além disso, a mortalidade por causas evitáveis explica 77% do efeito da distância a estabelecimentos Nível II na mortalidade infantil.'
                        : 'The figure below shows the results of the effect of distance to the nearest Level I, II, and III facilities on infant mortality, early neonatal mortality, and mortality from preventable causes for high-risk pregnancies. Note that proximity to Level I facilities (basic care) has no effect on mortality in these cases. On the other hand, distancing from Level II and III facilities increases infant mortality, particularly early neonatal mortality and mortality from preventable causes. Early neonatal mortality contributes 73% and 131% to the effect of distance to Level II and III facilities on infant mortality, respectively. Additionally, mortality from preventable causes explains 77% of the effect of distance to Level II facilities on infant mortality.'}
                </p>


                <Row className="charts-section mb-0 mt-0">
                    <Col md="2"></Col>
                    <Col md="8" className="chart-container">
                        <ChartCard
                            chart={language === 'pt' ? g14 : g14ing}
                            kind='img'
                            title={language === 'pt' ? 'Efeito da distância ao estabelecimento de saúde mais próximo na taxa de mortalidade infantil por nível de complexidade' : 'Effect of distance to the nearest health facility on infant mortality rate by level of complexity'}
                            fonte={language === 'pt' ? 'Elaboração própia a partir de Pinho Neto et al. (2023d, Tabela 4)' : 'Self-made from Pinho Neto et al. (2023d, Table 4)'}
                        />
                    </Col>
                    <Col md="2"></Col>
                </Row>
                <br />
                <p className="regular-text text-left">
                    {language === 'pt'
                        ? 'Maiores detalhes no estudo: '
                        : 'More details in the study: '}
                    <Link to="/pesquisas" active="/pesquisas'">
                        {language === 'pt'
                            ? 'Effects of distances on newborn outcomes in Brazil.'
                            : 'Effects of distances on newborn outcomes in Brazil.'}
                    </Link>
                </p>
                <br />

                <Row className="charts-section mb-0 mt-0">
                    <Col md="1"></Col>
                    <Col md="9">
                        <br />
                        <h3 className="charts-section-title">{language === 'pt' ? 'Referências' : 'References'}</h3>
                        <br />
                        <br />
                        <p className="regular-text text-left">
                            a) <Link to="/pesquisas" active="/pesquisas'">Pinho Neto et. al. Inequalities in access to health facilities for birth in Brazil. 2023.</Link>
                        </p>
                        <p className="regular-text text-left">
                            b) <Link to="/pesquisas" active="/pesquisas'">Pinho Neto et. al. Geographic availability of perinatal services in Brazil. 2023.</Link>
                        </p>
                        <p className="regular-text text-left">
                            c) <Link to="/pesquisas" active="/pesquisas'">Pinho Neto et. al. Traveling patterns of pregnant women: an analysis of 12 years of infant mortality in Brazil. 2023.</Link>
                        </p>
                        <p className="regular-text text-left">
                            d) <Link to="/pesquisas" active="/pesquisas'">Pinho Neto et. al. Effects of distances on newborn outcomes in Brazil. 2023.</Link>
                        </p>
                        <br />
                    </Col>
                </Row>


            </Container>

        </Container>
    )
}

export default Estatisticas;