import React, { Component } from 'react';
import './App.css';

// Routes
import { BrowserRouter, Switch, Route } from 'react-router-dom';

// Link para outras rotas
// import { Link } from 'react-router-dom'  // Usar para evitar refresh ao mudar de rota


// Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';  // Stylesheet

// Components
import Header from './components/header/header.component';
import Footer from './components/footer/footer.component';

// Pages
import Home from './pages/home/home.component'
import About from './pages/about/about.component'
import Resultados from './pages/estatisticas/estatisticas.component';
import Pesquisa from './pages/pesquisas/pesquisa.component';

// Assets
import ScrollToTop from './assets/ScrollToTop';  // Usar para fazer scroll to top ao mudar a rota

// Firebase
import { firestore } from './firebase/firebase.utils';
import { geohashQueryBounds, distanceBetween } from 'geofire-common';

// Language Context
import LanguageContext from './providers/LanguageContext';


class App extends Component {
  constructor(props) {
    super(props);  // calls the constructor method here
    this.state = {
      cep: '',
      cepValidado: '',
      cep_invalido: null,
      cep_encontrado: "",  // futuramente, exibir um alerta para CEPs não encontrados
      info_cep: { // informações iniciais
        cep: '',
        uf: 'SP',
        localidade: 'São Paulo',
        bairro: 'Sé',
        logradouro: 'Praça da Sé'
      },
      position: [-23.550426110202228, -46.633912732084326],  // posição default para inicializar o mapa
      language: 'pt',
      setLanguage: this.setLanguage,
    };
  }

  setLanguage = (newLanguage) => {
    this.setState({ language: newLanguage });
  }

  changeCEP = (cepBuscado) => {
    const cepTratado = cepBuscado.replace(/\D/g, '');  // CEP apenas com dígitos
    let caracteresInvalidosNaBusca = cepBuscado.replace(/[^\D]/gi, '').replace(/[\s\-./]/gi, '')  // obs: removi \ de \. e \/

    this.setState({
      cep: cepBuscado,
    })

    const cepValido = this.validaCEP(cepTratado, caracteresInvalidosNaBusca)

    if (cepValido) {
      this.setState({
        cepValidado: cepTratado
      })

      this.getInfoCEP(cepTratado)
      // console.log("Busquei o CEP na API");
    }
  }

  validaCEP = (cep, caracteresInvalidos) => {
    const validacao = /^[0-9]{8}$/;

    // Condição de prioridade máxima: se for buscado um caracter de texto, acusa erro antes de fazer os demais checks
    if (caracteresInvalidos !== "") {
      this.setState({
        cep_invalido: true
      })

      return false;
    } else if (cep !== "" && cep.length >= 8 && validacao.test(cep)) {  // caso em que o CEP é válido
      this.setState({
        cep_invalido: false,
      })

      return true;

    } else if (cep !== "" && cep.length >= 8 && !validacao.test(cep)) {
      this.setState({
        cep_invalido: true
      })

      return false

    } else if (cep !== "" && cep.length < 8) {
      this.setState({
        cep_invalido: true
      })

      return false;

    } else {
      return false;
    }
  }

  getInfoCEP = (cep) => {
    const CepCoords = require("coordenadas-do-cep");

    CepCoords.setOpcoes({
      precisao: 7,
      // busca_aproximada: false 
    })

    CepCoords.getByCep(`${cep}`)
      .then(info => {

        this.setState({
          // position: [info.lat, info.lon],
          info_cep: info,
        }) // () => {console.log(info)}

        this.getCoordenadasEndereco({ ...info })

      })
      .catch(err => {
        console.log(`Erro na busca por CEP: ${err}`)
        alert('CEP não encontrado. Por favor, verifique se o CEP digitado está correto.')
      });
  };

  getCoordenadasEndereco = ({ uf, localidade, bairro, logradouro }) => {
    // fazendo busca por endereço a partir das informações do CEP
    const CepCoords = require("coordenadas-do-cep");

    CepCoords.setOpcoes({
      precisao: 7,
      busca_aproximada: false
    })

    let endereco = `${uf}, ${localidade.toLowerCase()} ${bairro.toLowerCase()} ${logradouro.toLowerCase().normalize("NFD").replace(' de', '')}`
    // console.log(endereco)

    CepCoords.getByEndereco(endereco)
      .then(coords => {
        // fetching hospitals data from Firestore
        const hospitals_data = this.fetchData(coords.lat, coords.lon, 50);

        this.setState({
          position: [coords.lat, coords.lon],
          hospitalsData: hospitals_data
        })
      })
      .catch(err => {
        console.log(`Erro na busca por endereço: ${err}`)

        // Tratamento de erro 404
        if (err === 404) {
          endereco = `${uf}, ${localidade.toLowerCase()} ${logradouro.toLowerCase().normalize("NFD").replace(' de', '')}`
          CepCoords.getByEndereco(endereco)
            .then(coords => {
              // fetching hospitals data from Firestore
              const hospitals_data = this.fetchData(coords.lat, coords.lon, 50);

              this.setState({
                position: [coords.lat, coords.lon],
                hospitalsData: hospitals_data
              })
            })
            .catch(err => {
              console.log(`Erro na busca por endereço (tentativa 2): ${err}`)
            })
        }
      });
  }

  // Fetching hospitals data from Firestore
  fetchData = (currLat, currLong, distanceRadiusKM = 50) => {

    // query parameters
    const radiusInMeters = distanceRadiusKM * 1000;
    const center = [currLat, currLong];

    const bounds = geohashQueryBounds(center, radiusInMeters);
    const promises = [];

    // connecting to firebase
    const ref = firestore.collection('hospitals-geo-db');

    for (const b of bounds) {
      const q = ref.orderBy('geohash')
        .startAt(b[0])
        .endAt(b[1]);

      promises.push(q.get());
    }

    let data = [];
    Promise.all(promises).then((snapshots) => {
      const matchingDocs = [];

      for (const snap of snapshots) {
        for (const doc of snap.docs) {
          const lat = doc.get('lat_position');
          const lng = doc.get('lng_position');

          // We have to filter out a few false positives due to GeoHash
          // accuracy, but most will match
          const distanceInKm = distanceBetween([lat, lng], center);
          const distanceInM = distanceInKm * 1000;
          if (distanceInM <= radiusInMeters) {
            matchingDocs.push(doc.data());
          }
        }
      }

      return matchingDocs;
    }).then((matchingDocs) => {
      matchingDocs.forEach(doc => {
        data.push(doc)
      })
    })

    return data;
  };

  componentDidMount() {
    const hospitals_data = this.fetchData(this.state.position[0], this.state.position[1])

    this.setState({
      hospitalsData: hospitals_data
    })

    // Aviso de site em construção.
    /*alert('Site ainda em desenvolvimento. Você pode experienciar algumas falhas durante esse período. \n\nPedimos desculpas por qualquer inconveniente e continuamos trabalhando para entregar uma versão completa e estável desta aplicação.') */
  }


  
  



render(){
  return (
    <LanguageContext.Provider value={{ language: this.state.language, setLanguage: this.state.setLanguage }}>
      <div className="App Site">
        <BrowserRouter>
          <ScrollToTop>
            <Header />
            <Switch>
              <Route path="/" exact={true} render={(props) => (<Home {...props} isAuthed={true} state={this.state} changeCEP={this.changeCEP} validaCEP={this.validaCEP} />)} />
              <Route path="/about" component={About} />
              <Route path="/resultados" component={Resultados} />
              <Route path="/pesquisas" render={(props) => (<Pesquisa {...props} isAuthed={true} state={this.state} changeCEP={this.changeCEP} />)} />
              <Route path="/painel"  />
            </Switch>
            <Footer />
          </ScrollToTop>
        </BrowserRouter>
      </div>
    </LanguageContext.Provider>
  );
}
}

export default App;
