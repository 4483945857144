import React, { useContext } from 'react';
import './about.styles.css'


// Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';  // Stylesheet
import Container from 'react-bootstrap/Container'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// import CardDeck from 'react-bootstrap/CardDeck'

// Components
import { StaticMap } from '../../components/static-map/static-map.component'
import { TeamMember } from '../../components/team-member/team-member.component'

// Images
import IMGNeto from '../../img/equipe/neto.jpeg'
import IMGCecilia from '../../img/equipe/cecilia.jpg'
import IMGGilson from '../../img/equipe/gilson.jpeg'
import IMGMariana from '../../img/equipe/mariana.jpg'
import IMGFranklin from '../../img/equipe/franklin.jpeg'
import IMGDavila from '../../img/equipe/IMGDavila.jpeg'
import IMGFelipe from '../../img/equipe/IMGfelipe.jpeg'
import IMGSoraia from '../../img/equipe/IMGSoraia.jpeg'
import IMGCaio from '../../img/equipe/IMGCaio.jpeg'
// import IMGProposta from '../../img/about/achievement-5597527_1920.png'
// import IMGObjetivo from '../../img/about/target-2558687_1920.jpg'
// import IMGDistancia from '../../img/about/graphs.jpg'

// FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhoneAlt, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons'



import LanguageContext from '../../providers/LanguageContext';

const About = () => {
  const { language } = useContext(LanguageContext);

  return (
    <Container fluid className="about-body">
      <Container className="about-main-section">
        <h1 id="about-main-title">
          {language === 'pt' ? 'Sobre o Projeto' : 'About the Project'}
        </h1>

        <p className="text text-left">
          {language === 'pt'
            ? 'Nas últimas décadas, o Brasil passou por grandes mudanças sociais e econômicas, que puderam ser percebidas na melhoria de diversos indicadores sociais (inclusive de saúde) da população, levando a reduções nos índices de mortalidade infantil, por exemplo.'
            : 'In recent decades, Brazil has undergone major social and economic changes, which could be perceived in the improvement of various social indicators (including health) of the population, leading to reductions in infant mortality rates, for example.'}
        </p>

        <p className="text text-left">
          {language === 'pt'
            ? 'Embora o Brasil reconheça o direito ao acesso universal à saúde para toda a sua população, por meio do Sistema Único de Saúde, as condições dos serviços disponíveis são bastante heterogêneas no território nacional, apresentando níveis diferentes de precariedade a depender da região geográfica e dos contextos socioeconômicos. As unidades de saúde, por sua vez, também diferem entre si, por exemplo, nas suas práticas clínicas e hospitalares, qualidade das equipes, infraestrutura física e, por consequência, na própria provisão dos serviços de saúde. Tais heterogeneidades, por sua vez, revelam desigualdades no acesso e na qualidade do atendimento à saúde materna e infantil.'
            : 'Although Brazil recognizes the right to universal health access for its entire population, through the Unified Health System, the conditions of the available services are quite heterogeneous in the national territory, presenting different levels of precariousness depending on the geographic region and socioeconomic contexts. Health units, in turn, also differ from each other, for example, in their clinical and hospital practices, quality of teams, physical infrastructure and, consequently, in the provision of health services themselves. Such heterogeneities, in turn, reveal inequalities in access and quality of maternal and child health care.'}
        </p>

        <p className="text text-left">
          {language === 'pt'
            ? 'Um fator fundamental para o acompanhamento efetivo e integral das mães diz respeito às distâncias a serem percorridas entre suas residências e os locais de atendimento. Tais distâncias são particularmente importantes no contexto do Brasil, um país de dimensões continentais e onde as mortes maternas e infantis também estão ligadas à extensão do deslocamento para unidades com instalações e tratamentos adequados, principalmente se a gravidez apresenta alguma complicação.'
            : 'A key factor for the effective and comprehensive monitoring of mothers relates to the distances to be traveled between their residences and the places of care. These distances are particularly important in the context of Brazil, a country of continental dimensions where maternal and infant deaths are also linked to the extent of displacement to units with adequate facilities and treatments, especially if the pregnancy has any complications.'}
        </p>



        {/* <br />
      <pre>
          {"@article{id-artigo, \n\t author={Neto, V. and Machado, C. and Dutra, G. and Stussi, M.}, \n\t journal={nome do Journal}, \n\t title={Titulo}, \n\t year={2021} \n }"}
      </pre> */}
      </Container>

      <Jumbotron fluid className="secao-objetivos">
        <Container className="secao-objetivos-content">

          <h3 className="subtitle">{language === 'pt' ? 'Objetivos' : 'Goals'}</h3>
          <Row className="text-wrap">
            <Col md="4" style={{ "display": "flex", "justifyContent": "center", "position": "relative" }}>
              <div className="img-wrap-objetivos" />
            </Col>

            <Col md="8" style={{ "display": "flex", "justifyContent": "center", "position": "relative" }}>
              <div>
                <ul className="list">
                  <li className="regular-text">
                    {language === 'pt'
                      ? 'Estimar as distâncias intermunicipais percorridas pelas gestantes até o serviço de saúde no qual ocorre o parto'
                      : 'Estimate the inter-municipal distances traveled by pregnant women to the health service where childbirth occurs'}
                  </li>
                  <li className="regular-text">
                    {language === 'pt'
                      ? 'Descrever as desigualdades regionais na distância intermunicipal percorrida pelas gestantes para a realização do parto'
                      : 'Describe regional inequalities in the inter-municipal distance traveled by pregnant women for childbirth'}
                  </li>
                  <li className="regular-text">
                    {language === 'pt'
                      ? 'Identificar a associação entre as características socioeconômicas e fatores de risco das gestantes e recém-nascidos e a distância intermunicipal percorrida no momento do parto'
                      : 'Identify the association between the socioeconomic characteristics and risk factors of pregnant women and newborns and the inter-municipal distance traveled at the time of delivery'}
                  </li>
                  <li className="regular-text">
                    {language === 'pt'
                      ? 'Descrever as tendências temporais e regionais da mortalidade infantil e sua relação com a distância percorrida pelas gestantes até o serviço de atendimento ao parto'
                      : 'Describe the temporal and regional trends of infant mortality and its relationship with the distance traveled by pregnant women to the childbirth care service'}
                  </li>
                  <li className="regular-text">
                    {language === 'pt'
                      ? 'Analisar a proximidade das gestantes para a infraestrutura física e de equipamentos, bem como para os recursos humanos relevantes para o cuidado perinatal'
                      : 'Analyze the proximity of pregnant women to the physical infrastructure and equipment, as well as to the human resources relevant for perinatal care'}
                  </li>
                  <li className="regular-text">
                    {language === 'pt'
                      ? 'Analisar a relação causal entre as distâncias percorridas pelas mães para o atendimento ao parto e a mortalidade infantil no Brasil'
                      : 'Analyze the causal relationship between the distances traveled by mothers for childbirth care and infant mortality in Brazil'}
                  </li>
                </ul>
              </div>
            </Col>
          </Row>

          <h3 className="subtitle">{language === 'pt' ? 'Escopo' : 'Scope'}</h3>
          <Row className="text-wrap flex-row-reverse mb-2 pb-0">
            <Col md="6">
              <div className="img-wrap-proposta" />
            </Col>

            <Col md="6">
              <p className="regular-text text-left">
                {language === 'pt'
                  ? 'As distâncias assumem um papel relevante na determinação dos estabelecimentos onde as gestantes e mães são atendidas, e as diferenças nas unidades de saúde repercutem na qualidade do atendimento recebido. No entanto, desfechos adversos no período gestacional, ou logo após o parto, podem ser reflexos não apenas de percorrer um longo trajeto ou de receber cuidados inadequados nas unidades de saúde, mas também de condições socioeconômicas desfavoráveis (tais como baixa renda, baixa escolaridade etc.). Tais condições influenciam diretamente na qualidade de uma gravidez, tanto por meio da própria escassez de recursos como também pela falta de acesso a informações cruciais para o acompanhamento adequado da gestação. Separar estes fatores não é um exercício trivial, porque esses componentes estão relacionados e simultaneamente afetam os desfechos de saúde materno-infantil.'
                  : 'Distances play a key role in determining the establishments where pregnant women and mothers are cared for, and differences in health units affect the quality of care received. However, adverse outcomes during pregnancy, or shortly after delivery, can be a reflection not only of travelling a long distance or receiving inadequate care in health units, but also of unfavourable socio-economic conditions (such as low income, low education, etc.). These conditions directly influence the quality of a pregnancy, both through the scarcity of resources itself and the lack of access to crucial information for adequate pregnancy monitoring. Separating these factors is not a trivial exercise, as these components are related and simultaneously affect maternal-infant health outcomes.'}
              </p>
            </Col>
          </Row>

          <Row className="text-wrap flex-row">
            <Col md='12'>
              <p className="regular-text text-left">
                {language === 'pt'
                  ? 'A investigação acerca das distâncias entre as residências das gestantes e os serviços de saúde que elas acessam nos permite identificar onde estariam os maiores gargalos nos serviços de atendimento materno, bem como em quais localidades o direcionamento de ações pode exibir um maior custo-efetividade para melhoria da saúde materno-infantil. Enquanto a redução das distâncias entre as mães e os locais de atendimento parece algo desejável por si só, em um contexto de recursos limitados surge a necessidade de mensuração do efeito causal de tais distâncias sobre medidas objetivas de desfecho de saúde materna e infantil; o que buscamos fazer neste projeto.'
                  : 'Investigation of the distances between pregnant women\'s homes and the health services they access allows us to identify where the major bottlenecks in maternal care services might be, and in which locations the direction of actions might show a higher cost-effectiveness for improving maternal-child health. While reducing the distances between mothers and places of care seems desirable in itself, in a context of limited resources there arises the need to measure the causal effect of these distances on objective outcomes of maternal and child health; what we seek to do in this project.'}
              </p>

              {/* Imagem para preencher um pouco o espaço no meio do texto */}
              {/* <div className="img-wrap-fullwidth">
              <img src={IMGDistancia} width="100%" alt="img-distancia" id="img-distancia"/>
            </div> */}

              <p className="regular-text text-left">
                {language === 'pt'
                  ? 'Assim, pretendemos entender melhor as distâncias que separam gestantes e mães dos locais de acompanhamento materno-infantil, seus efeitos nos desfechos de saúde e as carências especificas de seus contextos. Isso possibilita um melhor direcionamento de esforços e de recursos públicos, visando mitigar os potenciais impactos das distâncias percorridas pelas gestantes. Irrestrito à área da saúde, pode-se também mencionar a melhoria da infraestrutura que facilite o deslocamento das mães (como qualidade das vias públicas, infraestrutura de transportes etc.) de modo a promover o acesso das gestantes a unidades de saúde sempre que haja necessidade. Por último, mas não menos importante, a própria investigação da qualidade do atendimento nos hospitais, seja medida pelos desfechos de saúde materno-infantil ou pela qualidade das equipes e da infraestrutura, se mostra fundamental para viabilizar algum monitoramento e nortear políticas públicas nesse campo.'
                  : 'Thus, we intend to better understand the distances that separate pregnant women and mothers from maternal-infant monitoring sites, their effects on health outcomes and the specific deficiencies of their contexts. This allows for better targeting of efforts and public resources, aiming to mitigate the potential impacts of the distances traveled by pregnant women. Unrestricted to the health area, we can also mention the improvement of the infrastructure that facilitates the displacement of mothers (such as quality of public roads, transport infrastructure etc.) in order to promote access of pregnant women to health units whenever there is a need. Last but not least, the investigation of the quality of care in hospitals, whether measured by maternal-infant health outcomes or by the quality of teams and infrastructure, is essential to enable some monitoring and guide public policies in this field.'}
              </p>
            </Col>
          </Row>

        </Container>
        <Container>
          <h1 className="title">{language === 'pt' ? 'Sobre o GCE' : 'About the GCE'}</h1>

          <p className="regular-text text-left">
            {language === 'pt'
              ? 'O Ministério da Saúde, por intermédio do Conselho Nacional de Desenvolvimento Científico e Tecnológico – CNPq, juntamente com a Fundação Bill & Melinda Gates, apoiam projetos no Brasil sobre o tema “Ciência de dados para melhorar a saúde materno-infantil, saúde da mulher e da criança no Brasil”, por meio do programa Grand Challenges Explorations (GCE).'
              : 'The Ministry of Health, through the National Council for Scientific and Technological Development - CNPq, together with the Bill & Melinda Gates Foundation, supports projects in Brazil on the theme "Data Science to Improve Maternal and Child Health, Women\'s and Children\'s Health in Brazil", through the Grand Challenges Explorations (GCE) program.'}
          </p>

          <p className="regular-text text-left">
            {language === 'pt'
              ? 'Tal iniciativa visa apoiar projetos de pesquisa que contribuam significativamente para o desenvolvimento científico, tecnológico e de inovação do País, voltados para a utilização de Ciência de Dados para melhorar a saúde materno-infantil no Brasil.'
              : 'This initiative aims to support research projects that contribute significantly to the scientific, technological and innovation development of the country, aimed at using Data Science to improve maternal and child health in Brazil.'}
          </p>
        </Container>
      </Jumbotron>


      <Jumbotron fluid className="secao-equipe">
        <LanguageContext.Consumer>
          {({ language }) => (
            <Container fluid>
              <h3 className="subtitle">{language === 'pt' ? 'Equipe' : 'Team'}</h3>
              <br />
              <Row className="card-deck">
                <TeamMember key={1} img={IMGNeto} name="Valdemar Neto" job={language === 'pt' ? "Principal Investigator (PI)" : "Principal Investigator (PI)"} text="" link="https://www.linkedin.com/in/valdemar-neto-9359a0204/" />
                <TeamMember key={2} img={IMGCecilia} name="Cecilia Machado" job={language === 'pt' ? "Co-PI" : "Co-PI"} link="https://sites.google.com/site/machadoc/" />
                <TeamMember key={4} img={IMGSoraia} name="Soraya Eyzaguirre" job={language === 'pt' ? "Pesquisador(a)" : "Researcher"} link="http://lattes.cnpq.br/7853681970773519" />
                <TeamMember key={5} img={IMGFelipe} name="Felipe Lima" job={language === 'pt' ? "Pesquisador(a)" : "Researcher"} link="https://www.linkedin.com/in/felipecrlima" />
                <TeamMember key={7} img={IMGCaio} name="Caio Miguez" job={language === 'pt' ? "Pesquisador(a)" : "Researcher"} link="https://www.linkedin.com/in/caiomiguez/" />
                <TeamMember key={8} img={IMGDavila} name="Dávila Meireles" job={language === 'pt' ? "Desenvolvedor(a)" : "Developer"} link="https://www.linkedin.com/in/d%C3%A1vila-de-carvalho-meireles-a4b73a191/" />
                <TeamMember key={6} img={IMGGilson} name="Gilson Dutra" job={language === 'pt' ? "Pesquisador(a)" : "Researcher"} text="" link="https://www.linkedin.com/in/gilson-j-dutra/" />
                <TeamMember key={3} img={IMGMariana} name="Mariana Stussi" job={language === 'pt' ? "Pesquisador(a)" : "Researcher"} link="https://www.linkedin.com/in/mariana-stussi-260131230/" />
                <TeamMember key={10} img={IMGFranklin} name="Franklin Oliveira" job={language === 'pt' ? "Desenvolvedor(a)" : "Developer"} text="" link="https://www.linkedin.com/in/franklin-oliveira95/" />
              </Row>
            </Container>
          )}
        </LanguageContext.Consumer>
      </Jumbotron>

      <Jumbotron fluid className='secao-contato'>
        <LanguageContext.Consumer>
          {({ language }) => (
            <Container className="inner-container" >
              <h3 className="subtitle white-text">{language === 'pt' ? 'Contato' : 'Contact'}</h3>
              <Row className="text-wrap flex-row-reverse mb-0">
                <Col md="5" className="mb-4 mt-1">
                  <br />
                  <h4 className="text white-text text-left">
                    <FontAwesomeIcon icon={faMapMarkedAlt} /> <b>{language === 'pt' ? 'Endereço' : 'Address'}</b> <br />
                  </h4>
                  <p className="regular-text white-text text-left">
                    {language === 'pt' ? (
                      <>
                        Praia de Botafogo, 190 - 4º andar <br />
                        Botafogo, Rio de Janeiro - RJ Brasil     <br />
                        CEP: 22250-900                     <br />
                      </>
                    ) : (
                      <>
                        Praia de Botafogo, 190 - 4th floor <br />
                        Botafogo, Rio de Janeiro - RJ Brazil     <br />
                        ZIP: 22250-900                     <br />
                      </>
                    )}
                  </p>

                  <br />
                  <h4 className="text white-text text-left">
                    <b>Site</b> <br />
                  </h4>
                  <p className="regular-text white-text text-left">
                    https://c3e.fgv.br/
                  </p>

                  <br />
                  <h4 className="text white-text text-left">
                    <FontAwesomeIcon icon={faPhoneAlt} /> <b>{language === 'pt' ? 'Telefone' : 'Phone'}</b>   <br />
                  </h4>
                  <p className="regular-text white-text text-left">
                    +55 21 3799-5860
                  </p>

                  <br />
                  <h4 className="text white-text text-left">
                    <FontAwesomeIcon icon={faEnvelope} /> <b>E-mail</b>   <br />
                  </h4>
                  <p className="regular-text white-text text-left">
                    gcedist@gmail.com
                  </p>
                </Col>

                <Col md="7" className="align-right mb-0 mt-3">
                  <StaticMap position={[-22.94117552094257, -43.17991996556573]} />
                </Col>
              </Row>
            </Container>
          )}
        </LanguageContext.Consumer>
      </Jumbotron>

      <Container>
        <LanguageContext.Consumer>
          {({ language }) => (
            <>
              <h1 className="title">{language === 'pt' ? 'Sobre o GCE' : 'About GCE'}</h1>

              <p className="regular-text text-left">
                {language === 'pt'
                  ? 'O Ministério da Saúde, por intermédio do Conselho Nacional de Desenvolvimento Científico e Tecnológico – CNPq, juntamente com a Fundação Bill & Melinda Gates, apoiam projetos no Brasil sobre o tema “Ciência de dados para melhorar a saúde materno-infantil, saúde da mulher e da criança no Brasil”, por meio do programa Grand Challenges Explorations (GCE).'
                  : 'The Ministry of Health, through the National Council for Scientific and Technological Development - CNPq, together with the Bill & Melinda Gates Foundation, supports projects in Brazil on the theme "Data Science to Improve Maternal and Child Health, Women\'s and Children\'s Health in Brazil", through the Grand Challenges Explorations (GCE) program.'}
              </p>

              <p className="regular-text text-left">
                {language === 'pt'
                  ? 'Tal iniciativa visa apoiar projetos de pesquisa que contribuam significativamente para o desenvolvimento científico, tecnológico e de inovação do País, voltados para a utilização de Ciência de Dados para melhorar a saúde materno-infantil no Brasil.'
                  : 'This initiative aims to support research projects that contribute significantly to the scientific, technological and innovation development of the country, aimed at using Data Science to improve maternal and child health in Brazil.'}
              </p>
            </>
          )}
        </LanguageContext.Consumer>
      </Container>


    </Container>
  )
}


export default About;
