import React,  { Component } from 'react';
import LanguageContext from '../../providers/LanguageContext';
import { Container, Jumbotron } from 'react-bootstrap';

import './pesquisa.styles.css';
import Article from '../../components/article/article.component';

import pdf1 from "../../articles/GCE_Policy_Paper1.pdf"
import pdf2 from "../../articles/GCE_Policy_Paper2.pdf"
import pdf3 from "../../articles/GCE_Policy_Paper3.pdf"
import pdf4 from "../../articles/GCE_Policy_Paper4.pdf"

class Pesquisa extends Component {
  render() {
    return (
      <LanguageContext.Consumer>
        {({ language }) => {
          const projectStudiesTitle = language === 'pt' ? 'Estudos Derivados do Projeto': 'Studies Derived from the Project';
          

          return (
            <div className="pesquisa-content">
              <Jumbotron fluid id="main-pesquisa-section">
                <Container>
                  <h1 id="pesquisa-main-title">{projectStudiesTitle}</h1>

            <p className="text">

            </p>

            {/* <Col xs={12} md={4}> */}
            <article>
              <Article
                title="Inequalities in access to health facilities for birth in
                Brazil"
                description="This article analyzes the traveled distances by pregnant women from their residence
                municipalities to the places of birth in order to document its magnitude and relationship
                to socioeconomic and birth-related conditions for over a decade in Brazil. Leveraging
                data between 2006 and 2017 from the Brazilian Information System of Live Births
                (SINASC), we observe that: (i) on average, there is a growing share of travels for
                birth across municipalities, peaking at 31% in 2017; (ii) such travels are getting longer,
                with average traveled kilometers approaching the 60 kilometers mark; (iii) the main
                factors that correlate to traveling are the ones regarding birth’s risk-level, such as low-
                weight fetus or multiple pregnancy; and (iv) municipalities with higher development levels are associated with shorter distances. Long distances remain an obstacle for
                accessing health facilities to give birth and figures point to a deteriorating scenario
                during studied years. Thus, politicians and policymakers must evaluate such demands
                to expand birth-related services’ provision, especially taking into consideration that
                location is a determinant factor for access and usage."
                pdfUrl={pdf1}
              />
            </article>
            {/*</Col>*/}


            <article>
              <Article
                title="Geographic availability of perinatal services in Brazil"
                description="Access to medical technology has proven crucial for saving a mother's and newborn's lives. However, how health systems should organize to guarantee such access is still an open question. This paper contributes to answering it by describing the multidimensionality of the geographical access to public perinatal services in Brazil between 2007 and 2021. We classify health facilities according to the availability of medical technology by production input - infrastructure, human resources, and equipment. Then, we estimate the distance of each municipality to the closest facility and its production input. Results show significant inequalities in access to facilities with the highest technology that persist throughout the decade. Further, we find imbalances between inputs, indicating that some improved more. As production inputs in health tend to be complementary, these differences could limit the facility's capability to provide a certain level of care, thus worsening the already existing inequalities.
                "
                pdfUrl={pdf3}
              />
            </article>



            <article>
              <Article
                title="Traveling patterns of pregnant women: an analysis of
                12 years of infant mortality in Brazil"
                description="Previous evidence suggests that the distance to health facilities increases infant
                mortality rates. In Brazil, women still face long distances to give birth, which could
                affect infant mortality. This study assesses pregnant women’s traveling patterns in
                cases that resulted in infant deaths. We assembled a matched dataset of births and
                infant mortality from the Brazilian Universal Health System administrative records between 2006 and 2017. First, we estimate infant mortality rates to describe time trends
                and geographic patterns. Mortality rates are classified by the age of death into early,
                late, and post-neonatal, and by cause of death into preventable and non-preventable
                causes. Next, restricting the dataset to cases that led to infant deaths, we estimate
                inter-municipal distances of three routes: residence to birth, birth to death, and residence to death. We aim to comprehend the frequency and average distance traveled
                by such movements. Infant mortality concentrates on the neonatal period and is primarily attributed to preventable causes. A larger share of women have traveled from
                their residence municipality to the birth. On the contrary, movements from birth to
                death are stable. Such results depend, certainly, on municipality size, as shown by a
                lower share of birth-death travels and of transfers within municipalities in larger population counts. Furthermore, disparities arise across regions and within more granular
                geographic divisions.
                "
                pdfUrl={pdf2}
              />
            </article>



            <article>
              <Article
                title="Effects of distances on newborn outcomes in Brazil"
                description="Travel distances can affect the delivery outcomes of pregnant women. Using detailed national data from the public health system (SUS), this paper identifies the effect of the distance to the place of delivery on infant mortality between 2006 and 2017 in Brazil. We focus on SUS cases to reduce demand bias because preferences and constraints to join the private system might differ. The paper follows a two-stage empirical strategy. First, we focus on a sample of low-risk pregnancies, among which traveling for childbirth would most likely reflect differences in geographic accessibility. Using a linear regression model, we find that moving to another municipality for childbirth increases the infant mortality rate by 17%, and each additional ten kilometers of journey raises the infant mortality rate by 0.1 points per 1000 live births. Results are robust to introducing multiple fixed effects, socioeconomic and risk factors. Second, we use a sample of high-risk pregnancies and a self-made complexity-of-care classification to identify the effect of the distance to specialized medical care on infant mortality. For this sample, the distance to any facility is less relevant than that to facilities with specialized medical technology: Living one standard deviation farther away from municipalities with level-II and level-III technologies increases the infant mortality rate by 0.94 and 0.4 points, respectively. Further, the geographic availability of neonatal intermediate and critical care beds is relevant for explaining the effect of the distance to specialized medical care on infant mortality."
                pdfUrl={pdf4}
              />
            </article>



          </Container>
        </Jumbotron>
        </div>
          );
        }}
      </LanguageContext.Consumer>
    );
  }
};
export default Pesquisa;





